import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { DocumentSearchIcon, DocumentTextIcon, LinkIcon, PlusCircleIcon, QuestionMarkCircleIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import API from "../../../api";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import TasksBreadcrumbs from "./_components/tasksBreadcrumbs";

export default function AreaTasksSettingsViewStep ({match, history}){

    const [documentTemplatesType, setDocumentTemplatesType] = useState(null);
    const [documentTemplates, setDocumentTemplates] = useState([]);
    const [documentTemplatesTypes, setDocumentTemplatesTypes] = useState([]);

    const [documentTemplatesTypeParent, setDocumentTemplatesTypeParent] = useState(null);
    const [documentAreaType, setDocumentAreaType] = useState(null);

    const [stepLinks, setStepLinks] = useState([]);

    const [step, setStep] = useState({});
    const [area, setArea] = useState({});
    const [task, setTask] = useState({});

    const [loading, setLoading] = useState(true);

    const _params = useParams();

    const saveArea = () => {

      setLoading(true);

      API.settings.area_tasks
        .updateStep(_params.step,{...step, resourceLinks: stepLinks})
        .then((res) => {
          if (res.success) {
              toast.success(res.message);
              history.push(`/settings/area_tasks/${_params.id}/${_params.task}`);
              // getData();
          } else {
              toast.error(res.message);
          }
          setLoading(false);
        })
        .catch((err) => {
            console.log("🚀 ~ saveArea ~ err.response.data.message:", err.response.data.message)
        });
    };
    

    let stepTypes = [
      "PlainStep",
      "UploadDocument",
      "GenerateDocument",
      // "YesNoOption",
      "SendEmail",
    ];

    useEffect(() => {

        API.settings.area_tasks
          .get(_params.id)
          .then((res) => {
              setArea(res);
        });

        API.settings.area_tasks
          .getTask(_params.task)
          .then((res) => {
              setTask(res);
        })

        API.settings.area_tasks.getStep(_params.step).then(res => {
          setStepLinks(res.resourceLinks);
          setStep(res);
          API.settings.doc_types().list().then((types) => {
            setDocumentTemplatesTypes(types);
                API.settings.doc_templates().list().then((res) => {
                    setDocumentTemplates(res);
                    setLoading(false)
                });
            });
        })
        

        // setDetailsTask(theDetailsTask); // details, params 2
    },[])

    /*let documentTemplatesFiltered = documentTemplates.filter(e => 
        e.recipient && 
        Array.isArray(e.recipient) &&
        e.recipient.some(recipient => recipient.label === documentTemplatesType)
      ); */

      let documentTemplatesFiltered = documentTemplates.filter(e => 
        e.recipient && 
        Array.isArray(e.recipient) &&
        (e.recipient.some(recipient => recipient.label === documentTemplatesType) || e.recipient.some(recipient => recipient === documentTemplatesType)) &&
        !e.archived &&
        !e.global_template
      );
    
      let globalTemplates = documentTemplates.filter(e => 
        e.global_template
      );


    const handleChange = (e) => {
        setStep({
            ...step,
            [e.target.name] : e.target.value
        })
    }

    let links = stepLinks ? stepLinks : [];

    if(loading){
      return <Loader />
    }

    let current = documentTemplates.find(e => e._id == step.defaultTemplate)

    return (
      <>
      <TasksBreadcrumbs panes={[
        { name: `Area - ${area && area.area && area.area.area}`, href: `/settings/area_tasks/${area && area.area && area.area._id}`, current: false},
        { name: `Task - ${task && task.title}`, href: `/settings/area_tasks/${area && area.area && area.area._id}/${task && task._id}`, current: false},
        { name: `Step - ${step && step.title}`, href: `#`, current: true},
      ]} />
      <Segment>
            <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-3">
                        <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Step Details: {step && step.title}
                        </h3>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-3 md:mt-0">
                        <div className="mt-4">
                        <div className={`text-lg`}>
                            {step && <>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none w-full">
                                <dt className="text-sm font-medium text-gray-500">
                                    Title
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input
                                    type="text"
                                    value={step.title}
                                    onChange={handleChange}
                                    name="title"
                                    id="title"
                                    className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm w-full"
                                    />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                <dt className="text-sm font-medium text-gray-500">
                                    Order
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input
                                    value={step.order}
                                    onChange={handleChange}
                                    type="number"
                                    name="order"
                                    id="order"
                                    className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                    />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                              <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                  Step Type <QuestionMarkCircleIcon className="h-4 inline-block" />
                              <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                  Types:
                                  <ul>
                                  <li>PlainStep: Ordinary Step with a Mark Completed.</li>
                                  <li>UploadDocument: Uploader for a document on Step.</li>
                                  <li>GenerateDocument: Generate document from your Templates on Step.</li>
                                  <li>SendEmail: Ordinary Step with Completing Task sending to the mailbox to email out.</li>
                                  </ul>
                              </span>
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <select
                                  id="stepType"
                                  name="stepType"
                                  class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                  onChange={handleChange}
                                  >
                                  {stepTypes.map((t) => {
                                      return (
                                      <option selected={step.stepType == t}>
                                          {t}
                                      </option>
                                      );
                                  })}
                                  </select>
                              </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                      Send To Accounts <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                      Send email of Task to Accounts when completed
                                    </span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <input
                                    checked={
                                    step.sendAccounts
                                    }
                                    onChange={(e) => {
                                        setStep({
                                            ...step,
                                            sendAccounts : !step.sendAccounts
                                        })
                                    }}
                                    id="sendAccounts"
                                    name="sendAccounts"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                      Select Court <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                      Select Court option when completing the task
                                    </span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <input
                                    checked={
                                    step.selectCourt
                                    }
                                    onChange={(e) => {
                                        setStep({
                                            ...step,
                                            selectCourt : !step.selectCourt
                                        })
                                    }}
                                    id="selectCourt"
                                    name="selectCourt"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                    Individual <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                    Individual: Show step for Individual?
                                    </span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <input
                                    checked={
                                    step.contactDependant
                                    }
                                    onChange={(e) => {
                                        setStep({
                                            ...step,
                                            contactDependant : !step.contactDependant
                                        })
                                    }}
                                    id="contactDependant"
                                    name="contactDependant"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                    Corporate <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                    Corporate: Show step for Corporates?
                                    </span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <input
                                      checked={
                                        step.corporateDependant
                                      }
                                      onChange={(e) => {
                                        setStep({
                                              ...step,
                                              corporateDependant : !step.corporateDependant
                                          })
                                      }}
                                      id="corporateDependant"
                                      name="corporateDependant"
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                  />
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                <dt className="text-sm font-medium text-gray-500">
                                    Due Days
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input
                                    value={step.dueDays}
                                    onChange={handleChange}
                                    type="number"
                                    name="dueDays"
                                    id="task_dueDays"
                                    className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                    />
                                </dd>
                              </div>

                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                  Charge Fee
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <input
                                      checked={
                                        step.chargeFee
                                      }
                                      onChange={(e) => {
                                          setStep({
                                              ...step,
                                              chargeFee : !step.chargeFee
                                          })
                                      }}
                                      id="chargeFee"
                                      name="chargeFee"
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                  />

                                  {step.chargeFee && <>

                                    <br/><br/>

                                    <input 
                                      type="radio"
                                      checked={step.chargeFeeType == "scaling"}
                                      onChange={(e) => {
                                          setStep({
                                              ...step,
                                              chargeFeeType : "scaling"
                                          })
                                      }}
                                      id="chargeFeeTypeScale"
                                      name="chargeFeeType"
                                      value="scaling"
                                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                                    />
                                    <label for="chargeFeeTypeScale" className="mr-4">Scaling</label>
                                    <input 
                                      type="radio"
                                      checked={step.chargeFeeType == "upfront"}
                                      onChange={(e) => {
                                          setStep({
                                              ...step,
                                              chargeFeeType : "upfront"
                                          })
                                      }}
                                      id="chargeFeeTypeUpfront"
                                      name="chargeFeeType"
                                      value="upfront"
                                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                                    />
                                    <label for="chargeFeeTypeUpfront" className="mr-4">Upfront</label>
                                    {step.chargeFeeType == "upfront" && <>
                                    {step.chargeFeeAmountType == "fixed" ? "£" : ""}
                                      <input 
                                        type="number"
                                        onChange={handleChange}
                                        id="chargeFeeAmount"
                                        name="chargeFeeAmount"
                                        value={step.chargeFeeAmount}
                                        className="inline-block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                      />
                                    {step.chargeFeeAmountType == "percent" ? "%" : ""}
                                    </>}
                                    
                                  </>}
                                </dd>
                              </div>
                          

                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                <dt className="text-sm font-medium text-gray-500">
                                    Default Document <br/>
                                    {current ? `Selected: ${current.description}` : step.defaultTemplate ? "Has Document but can't get Name of Document!" : "No Document Selected!"}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 grid-cols-4 grid">

                                    <Menu
                                      as="div"
                                      className="relative inline-block text-left w-full"
                                    >
                                      <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                        <DocumentSearchIcon
                                          className="-ml-1 mr-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        Find Document 
                                      </Menu.Button>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            <Menu.Item>
                                              <div
                                                onClick={() => setDocumentAreaType("law")}
                                                className={
                                                  "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                }
                                              >
                                                Area of Law Templates.
                                              </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                              <div
                                                onClick={() => setDocumentAreaType("global")}
                                                className={
                                                  "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                }
                                              >
                                                Global Templates
                                              </div>
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>

                                    {documentTemplatesType && documentAreaType === "law" ?
                                    <div className="mb-4 flex px-3">
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left w-full"
                                      >
                                        {documentTemplatesFiltered.length > 0 ? <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                          <DocumentTextIcon
                                            className="-ml-1 mr-2 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                          Select {documentTemplatesType} Template:
                                        </Menu.Button>

                                        :

                                        <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 bg-alert px-4 py-2 text-sm font-medium text-white shadow-sm cursor-not-allowed">
                                          <XIcon
                                            className="-ml-1 mr-2 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                          {documentTemplatesType} Has No Templates!
                                        </Menu.Button>}

                                        {documentTemplatesFiltered.length > 0 &&

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                              {documentTemplatesFiltered.map((template) => {
                                                return <Menu.Item>
                                                <div
                                                  onClick={() => {
                                                    setStep({
                                                      ...step,
                                                      defaultTemplate: template._id
                                                    })
                                                   }}
                                                  className={
                                                    "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                  }
                                                >
                                                  {template.name}
                                                </div>
                                              </Menu.Item>
                                              })}
                                            </div>
                                          </Menu.Items>
                                        </Transition>}
                                      </Menu>
                                    </div> : documentAreaType === "global" &&
                                    <div className="mb-4 flex px-3">
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left w-full"
                                      >
                                        <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                          <DocumentTextIcon
                                            className="-ml-1 mr-2 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                          Select Global Template:
                                        </Menu.Button>

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                              {globalTemplates.map((template) => {
                                                return <Menu.Item>
                                                <div
                                                  onClick={() => {
                                                    setStep({
                                                      ...step,
                                                      defaultTemplate: template._id
                                                    }) }}
                                                  className={
                                                    "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                  }
                                                >
                                                  {template.name}
                                                </div>
                                              </Menu.Item>
                                              })}
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div> }

                                    {documentAreaType === "law" && 
                                      <>
                                        {documentTemplatesTypeParent && <div className="mb-4 flex px-3">
                                          <Menu
                                            as="div"
                                            className="relative inline-block text-left w-full"
                                          >
                                            <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                              <DocumentSearchIcon
                                                className="-ml-1 mr-2 h-5 w-5"
                                                aria-hidden="true"
                                              />
                                              Select Sub Category from {documentTemplatesTypeParent.name}
                                            </Menu.Button>

                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                            >
                                              <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <div
                                                      onClick={() => {
                                                        setDocumentTemplatesType(documentTemplatesTypeParent.name) }}
                                                      className={
                                                        "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                      }
                                                    >
                                                      {documentTemplatesTypeParent.name} ({documentTemplatesTypeParent.shortcode})
                                                    </div>
                                                  {documentTemplatesTypes.filter(e => e.archived != true && e.parent && e.parent._id == documentTemplatesTypeParent._id ).map((template) => {
                                                    return <Menu.Item>
                                                    <div
                                                      onClick={() => {
                                                        setDocumentTemplatesType(template.name) }}
                                                      className={
                                                        "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                      }
                                                    >
                                                      {template.name} ({template.shortcode})
                                                    </div>
                                                  </Menu.Item>
                                                  })}
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </div>}

                                        <div className="mb-4 flex px-3">
                                          <Menu
                                            as="div"
                                            className="relative inline-block text-left w-full"
                                          >
                                            <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                              <DocumentSearchIcon
                                                className="-ml-1 mr-2 h-5 w-5"
                                                aria-hidden="true"
                                              />
                                              Select Area of Law
                                            </Menu.Button>

                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                            >
                                              <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                  {documentTemplatesTypes.filter(e => e.archived != true && !e.parent).map((template) => {
                                                    return <Menu.Item>
                                                    <div
                                                      onClick={() => setDocumentTemplatesTypeParent(template)}
                                                      className={
                                                        "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                      }
                                                    >
                                                      {template.name} ({template.shortcode})
                                                    </div>
                                                  </Menu.Item>
                                                  })}
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </div>

                                      </>
                                    }

                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                <dt className="text-sm font-medium text-gray-500">
                                    Resource Links
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <table
                                    className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                        >
                                          Link
                                        </th>
                                        <th
                                          scope="col"
                                          className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                        >
                                          URL
                                        </th>
                                        <th
                                          scope="col"
                                          className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                        >
                                          <button
                                                type="button"
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => {
                                                  links.push({title: "", url: ""})
                                                  setStepLinks([].concat(links))
                                                }}
                                              >
                                            <PlusCircleIcon className="h-4 inline-block"/>
                                          </button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-300">
                                      {links.length > 0 ? (
                                        links.map((item, index) => (
                                          <tr
                                            className={`hover:bg-gray-100 focus:bg-gray-100`}
                                            key={index}
                                          >
                                            <td className="px-4 py-4">
                                              <input
                                                value={
                                                  item.title
                                                }
                                                onChange={(e) => {
                                                  links[index].title = e.target.value;
                                                  setStepLinks([].concat(links))
                                                }}
                                                type="text"
                                                name="link"
                                                id="link"
                                                className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                              />
                                            </td>
                                            <td className="px-4 py-4">
                                              <input
                                                value={
                                                  item.url
                                                }
                                                onChange={(e) => {
                                                  links[index].url = e.target.value;
                                                  setStepLinks([].concat(links))
                                                }}
                                                type="text"
                                                name="url"
                                                id="url"
                                                className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                              />
                                            </td>
                                            <td className="px-4 py-4">
                                              <button
                                                type="button"
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => {
                                                  links.splice(index, 1);
                                                  setStepLinks([].concat(links))
                                                }}
                                              >
                                              <TrashIcon className="h-4 inline-block"/>
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr className={`text-black `}>
                                          <td colSpan={5} className="px-4 py-4">
                                            No Links...
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </dd>
                              </div>
                              </>}
                        </div>
                        </div>
                    </div>
                    <div className="md:col-span-3 border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          onClick={() => history.push(`/settings/area_tasks/${_params.id}/${_params.task}`)}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent mr-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Back
                        </button>
                        <button
                          onClick={saveArea}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          Save
                        </button>
                      </div> 
                    </div>
                </div>
            </div>
        </Segment>
      </>
        );
  }