import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { Dialog, Menu, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { DocumentSearchIcon, DocumentTextIcon, LinkIcon, PlusCircleIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
import { DataGridPro, GridRowsProp, GridColDef, GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { MenuItem, Popover } from '@mui/material';
import { GiCheckMark, GiChecklist, GiCrossMark } from "react-icons/gi";
import TasksBreadcrumbs from "./_components/tasksBreadcrumbs";

export default function AreaTasksSettingsView ({match, history}) {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [area, setArea] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const _params = useParams();

  const getData = () => {
    setGlobalError(null);
    API.settings.area_tasks
      .get(params.id)
      .then((res) => {
        setArea(res);
        setRows(res.tasks
          .filter((e) => (showArchived ? true : !e.archived))
          .sort((a, b) => a.order - b.order)
          .map((t, i) => ({
            ...t,
            id: "Task #" + (i + 1),
            action: t._id,
            // task: t,
            type: t.taskType,
            individual: t.contactDependant,
            corporate: t.corporateDependant,
            due: t.dueDays,
            steps_count: t.steps ? t.steps.length : 0,
            _id: t._id
          })));
        
      })
        
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError(err.response.data.message);
        } else {
          setGlobalError(
            "There was an unexpected error while trying to retrieve the area tasks from the server."
          );
        }
      })
      .finally(() => {
        setIsInitialLoading(false);
      });

  };


  useEffect(() => {
    getData();
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (isInitialLoading) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  const saveArea = (redirect = false) => {
    API.settings.area_tasks
      .update(params.id, area)
      .then((res) => {
        if (res.success) {
          toast.success(`Updated Area successfully`);
          getData();

          if(redirect){
            history.push(params.org_id ? `/admin/organisations/${params.org_id}/area_tasks` : `/settings/area_tasks`);
          }

        } else {
          toast.error(`Error updating Area`);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError(err.response.data.message);
        } else {
          setGlobalError(
            "There was an unexpected error while trying to update the area."
          );
        }
      });
  };

  const areaAdd = () => {
    API.settings.area_tasks.addTask(_params.id).then(res => {      
      if(res.success){
        toast.success(res.message);
        history.push(`/settings/area_tasks/${_params.id}/${res._task}`)
      }else{
        toast.error(res.message)
      }
    })
  };


  // let documentTemplatesFiltered = documentTemplates.filter(e => e.recipient && e.recipient.name == documentTemplatesType);


  const updateRowPosition = async (oldIndex, targetIndex, rows) => {
    // Swap the rows in the array
    const newRows = [...rows];
    const [movedRow] = newRows.splice(oldIndex, 1);
    newRows.splice(targetIndex, 0, movedRow);
  
    // Update the order property of each row based on their position in the array
    newRows.forEach((row, index) => {
      row.order = index + 1; // Update the order starting from 1
    });
  
    return newRows;
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    setRows(newRows);
    console.log("🚀 ~ handleRowOrderChange ~ newRows:", newRows)
    API.settings.area_tasks.update(area.area._id, {tasks: newRows}).then(() => {
      setLoading(false);
      toast.success("Area Task Re-ordered successfully");
    })
    
  };
    

  const columns: GridColDef[] = [
    { headerName: "Order", field: "order", width: 150, pinnable: false }, 
    { headerName: "Task Nr", field: "id", width: 150, pinnable: false }, 
    { headerName: "Nr of Steps", field: "steps_count", width: 150, pinnable: false }, 
    { headerName: "Description", field: "title", width: 450, pinnable: false }, 
    { headerName: "Type", field: "type", width: 150, pinnable: false }, 
    { headerName: "Individual", field: "individual", width: 150, pinnable: false, renderCell: (params) => (params.row.individual) ? <GiCheckMark /> : <GiCrossMark />}, 
    { headerName: "Corporate", field: "corporate", width: 150, pinnable: false, renderCell: (params) => (params.row.corporate) ? <GiCheckMark /> : <GiCrossMark />}, 
    { headerName: "Due Days", field: "due", width: 150, pinnable: false }, 
    {
      field: 'actions',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        const id = params.row.action;
        return (
          <>
          <button
                onClick={() =>
                  history.push(`/settings/area_tasks/${_params.id}/${id}`)
                }
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
              {"View Details"}
            </button>
          </>
          
        );
      },
    }
  ];

  return (
    <>
      <TasksBreadcrumbs panes={[
        { name: `Area - ${area && area.area && area.area.area}`, href: `#`, current: true},
      ]} />
    
    <Segment>
      {/* {renderLinksModal()} */}
      {/* {renderAutomationModal()} */}
      {/*  
      <RenderTaskModal 
        theArea={area} 
        areaRemove={(archived, type, parentInd, stepInd) => areaRemove(archived, type, parentInd, stepInd)}
        documentTemplates={documentTemplates}
        documentTemplatesTypes={documentTemplatesTypes}
        showArchived={showArchived}
        areaAdd={() => areaAdd}
        saveArea={() => saveArea}
        openLinksModal={() => openLinksModal}
      />*/}
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Area of Law, Task Builder
              </h3>
              <p className="mt-1 text-sm text-gray-600">Build out the task's workflow for the area of law selected.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="area"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        onChange={(e) => {
                          setArea({
                            ...area,
                            area: {
                              ...area.area,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        value={area && area.area && area.area.area}
                        type="text"
                        name="area"
                        id="area"
                        className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="area"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Practice Area
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        onChange={(e) => {
                          setArea({
                            ...area,
                            area: {
                              ...area.area,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        disabled
                        value={area && area.area && area.area.practiceArea}
                        type="text"
                        name="practiceArea"
                        id="practiceArea"
                        className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 gap-6">
                  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">

                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Tasks (
                        {area &&
                          area.tasks.filter((e) =>
                            showArchived ? true : !e.archived
                          ).length}
                        )
                        <button
                          onClick={() => areaAdd()}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Add Task
                        </button>
                        <Switch.Group
                          as="div"
                          className="flex items-center float-right"
                        >
                          <Switch
                            checked={showArchived}
                            onChange={setShowArchived}
                            className={classNames(
                              showArchived ? "bg-primary" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                showArchived
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              Show Archived
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </h3>

                      <DataGridPro 
                        rows={rows} 
                        columns={columns} 
                        initialState={{ pinnedColumns: { left: ['order'], right: ['actions'] } }}
                        rowReordering
                        loading={loading}
                        onRowOrderChange={handleRowOrderChange}
                      />

                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          onClick={() => areaAdd()}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent mr-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Add Task
                        </button>
                        <button
                          onClick={() => saveArea(true)}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          Save
                        </button>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Segment>

    </>
    
  );

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Areas of Law, Task Builer
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Create workflows withs Tasks & Steps
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Area
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {area &&
                        area.length > 0 &&
                        area.map((a, index) => (
                          <tr key={"area_" + index}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                              {a.area}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href={"/settings/area_tasks/" + a._id}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                View<span className="sr-only">, {a.area}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
