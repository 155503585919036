import React, {Fragment, useState, useEffect, useCallback } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Input,
  Toggle,
  SelectZero
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import Loader from "../../../../components/Loader";
import { CheckIcon, PlayIcon, StopIcon, XIcon, DocumentSearchIcon, DocumentTextIcon, PrinterIcon, ArrowDownIcon,  DocumentIcon, ExclamationCircleIcon, LinkIcon, MailIcon, PaperClipIcon, PencilIcon } from "@heroicons/react/solid";
import { BiErrorAlt, BiErrorCircle, BiRun } from "react-icons/bi";
import MatterBreadcrumbs from "../../_components/MatterBreadcrumbs";
import { Dialog, Menu, Transition } from "@headlessui/react";
import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";
import toast from "react-hot-toast";
import Select from "react-tailwindcss-select";
import DatePicker from "react-datepicker";
import _appConfig from "../../../../_appConfig";
import HtmlEditor from "../../../../components/widgets/HtmlEditor";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

function Edit({auth}) {
  const history = useHistory();
  const params = useParams();

  const [courtsList, setCourtsList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [matter, setMatter] = useState(null);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    rateDetails: "",
    currentRate: 0,
    time: 0,
    end_time: null,
    isBillable: false
  });
  const [task, setTask] = useState({}); //from view is known as data!!

  const [defaultAdminRate, setDefaultAdminRate] = useState(0);
  const [matterDebtors, setMatterDebtors] = useState([]);

  const [mountLoading, setMountLoading] = useState(true);

  const [timerClock, setTimerClock] = useState(0);
  const [timer, setTimer] = useState(true);

  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const [generatingDocument, setGeneratingDocument] = useState(false);
  const [generatingDocumentError, setGeneratingDocumentError] = useState(false);
  const [generatingDocumentErrorMessage, setGeneratingDocumentErrorMessage] = useState("");

  const [templatesLoading, setTemplatesLoading] = useState(false);
  
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [defaultDocumentTemplate, setDefaultDocumentTemplate] = useState({});
  const [documentTemplatesTypes, setDocumentTemplatesTypes] = useState([]);
  const [documentTemplatesType, setDocumentTemplatesType] = useState(null);
  const [documentTemplatesTypeParent, setDocumentTemplatesTypeParent] = useState(null);
  const [documentAreaType, setDocumentAreaType] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);

  const [documentReceipts, setDocumentReceipts] = useState([]);
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(null);
  const [documentContent, setDocumentContent] = useState("");
  const [documentHeader, setDocumentHeader] = useState("");
  const [dateVerbose, setDateVerbose] = useState("");
  const [parties, setParties] = useState([]);
  const [documentFooter, setDocumentFooter] = useState("");
  const [letterHeaders, setLetterHeaders] = useState([]);

  const [documentWizard,setDocumentWizard] = useState(false);
  const [documentWizardStep,setDocumentWizardStep] = useState(0);

  useEffect(() => {
    if (mountLoading) {
      API.matters.get(params.matter_id).then((res) => {

        let scale = res.scale ? res.scale : "";
        let fee_thresholds = res.fee_thresholds ? res.fee_thresholds : [];
        setMatter(res);

        API.matters.tasks
        .get(params.matter_id, params.task_id)
        .then((r) => {
          setTask(r);
          setData({
            ...data, description: r.description
          })

          if(r.area_step.defaultTemplate){
            API.settings.doc_templates().get(r.area_step.defaultTemplate).then(_doc => {
              setDefaultDocumentTemplate(_doc);

              let temp = documentTemplates.concat(_doc)
              setDocumentTemplates(temp)
            })
          }

        });

        API.settings.courts.get().then((c) => {
          setCourtsList(c);
        });

        // API.matters.get(params.matter_id).then((tsk) => {

          API.matters.debtors
            .getAll(params.matter_id)
            .then((res2) => {
              setMatterDebtors(res2);

              API.users
                .getAll()
                .then((res3) => {

                  let assigned = null;
                  let assigned_rate = 0;

                  let rateDetails = "Using User Settings Rate";

                  setUserList(
                    res3.users.map((a) => {
                      let ft = fee_thresholds.filter(x => x.user == a._clioId);

                      let rate = a.rate;
                      
                      if(scale === "hourlyScale" && ft && ft[0]){
                        rate = parseFloat(ft[0].fee);
                      }

                      if(auth && a.email == auth.user.email){
                        assigned = a._id;
                        assigned_rate = rate; 

                        if(scale === "hourlyScale" && ft && ft[0]){
                          rateDetails = "Using Matter Hourly Fee Rate";
                        }

                      }
                      return {
                        text: a.name,
                        value: a._id,
                        rate,
                        rateDetails
                      };
                    })
                  );

                  if(assigned){
                    setData({ ...data, assigned, currentRate: assigned_rate, rateDetails, isBillable: res.billing_method == "hourly" });
                  }else{
                    setData({ ...data, rateDetails, isBillable: res.billing_method == "hourly" });
                  }

                  API.settings.get().then(res4 => setDefaultAdminRate(res4.adminRate)).catch((e) => CommonFunctions.handleError(e))

                  API.settings.email_templates().list().then((emails) => {
                    setEmailTemplates(emails);
                    setMountLoading(false);
                  });

                })
                .catch((e) => CommonFunctions.handleError(e));  

            })
          .catch((e2) => CommonFunctions.handleError(e2));

        // }).catch((e3) => CommonFunctions.handleError(e3));

        
    
      })
      
    }
  }, [mountLoading]);

  useEffect(() => {

    switch(documentAreaType){
      case "law":

        setTemplatesLoading(true);

        API.settings.doc_types().list(null, "law").then((res) => {
          setDocumentTemplatesTypes(res);

          setTemplatesLoading(false);
        });

        break;
      case "global":

        setTemplatesLoading(true);

        API.settings.doc_templates().list(null, "__global").then((res) => {

          if(task.area_step.defaultTemplate){
            API.settings.doc_templates().get(task.area_step.defaultTemplate).then(_doc => {
              let temp = res.concat(_doc)
              setDocumentTemplates(temp)
              setTemplatesLoading(false);
            })
          }else{
            setDocumentTemplates(res)
            setTemplatesLoading(false);
          }

        });

        break;
    }

  }, [documentAreaType])

  useEffect(() => {
    if(documentTemplatesType){
      setTemplatesLoading(true);
      API.settings.doc_templates().list(null, documentTemplatesType).then((res) => {
        setDocumentTemplates(res);

        setTemplatesLoading(false);
      });
    }
  }, [documentTemplatesType])

  const headerTemplates = useCallback(() => {
    return API.settings.letter_headers().list();
  }, []);

  const footerTemplates = useCallback(() => {
    return API.settings.letter_headers().list(); // TODO bring back just the templates for foorer
  }, []);


  const onChange = (e) => {

    let d = data;

    if((e.target.name == "time" && e.target.value > 0) && data.isBillable !== true){
      d.isBillable = true;
    }else if((e.target.name == "time" && e.target.value == 0) && data.isBillable){
      d.isBillable = false;
    }

    setData({ ...d, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  useEffect(() => {
    let interval = null;
  
    if (timer) {
      setData((prevData) => ({
        ...prevData,
        start_time: new Date(),
      }));
  
      interval = setInterval(() => {
        setTimerClock((prevTime) => prevTime + 10);
        setData((prevData) => ({
          ...prevData,
          time: (prevData.time * 60000 + 10) / 60000, // Increment in milliseconds, convert to minutes / 6
        }));
      }, 10);
    } else {
      setData((prevData) => ({
        ...prevData,
        end_time: new Date(),
      }));
      clearInterval(interval);
    }
  
    return () => clearInterval(interval);
  }, [timer]);

  let selectCourt = <div className="mb-4 flex px-3">
  <Menu
    as="div"
    className="relative inline-block text-left w-full"
  >
    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
      <DocumentSearchIcon
        className="-ml-1 mr-2 h-5 w-5"
        aria-hidden="true"
      />
      Select Court-
    </Menu.Button>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {courtsList.map((c) => {
            return <Menu.Item>
            <div
              onClick={() => {
                API.matters.tasks.update(params.matter_id, params.task_id, {court: c._id}).then(() => {setMountLoading(true); toast.success("Court Assigned to task!")})
                // setOpenDocumentModal(template._id)
                // setDocumentContent(template.content);
                /*
              API.matters.tasks
                .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                .then(() => setMountLoading(true))
              .catch((e) => CommonFunctions.handleError(e))}*/ }}
              className={
                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              }
            >
              {c.name}
            </div>
          </Menu.Item>
          })}
        </div>
      </Menu.Items>
    </Transition>

  </Menu>
  </div>;

  let completeButtons;

  if(task.isStep){
    
    completeButtons = task.area_step && task.area_step.stepType === "YesNoOption" && !task.complete ? <>
    <Button
      colour="warning"
      label="Mark Yes"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {...data, YesNoOption: "Yes"})
          .then(() => history.push(
            `/matters/${params.matter_id}/tasks/${params.task_id}`
          ))
          // .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg"
      icon={CheckIcon}
    />
    <Button
      colour="warning"
      label="Mark No"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {...data, YesNoOption: "No"})
          .then(() => history.push(
            `/matters/${params.matter_id}/tasks/${params.task_id}`
          ))
          // .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-4 text-lg"
      icon={XIcon}
    />
    </>: !task.complete ? <>

    {task.area_step.defaultTemplate && defaultDocumentTemplate && 

    <div className="mb-4 flex px-3">
      <button
          onClick={() => {  
              setDocumentReceipts(defaultDocumentTemplate.recipients ? defaultDocumentTemplate.recipients : [])
              setOpenDocumentModal(defaultDocumentTemplate._id)
              setDocumentContent(defaultDocumentTemplate.content);
              setDocumentHeader(defaultDocumentTemplate.header_content);
              setDocumentFooter(defaultDocumentTemplate.footer_content);
              setData({
                ...data,
                document_fields_map: defaultDocumentTemplate.document_fields
              });
              setDocumentWizard(false)
            
          }}
          className="text-lg flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-orange-500 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
        <DocumentTextIcon
          className="-ml-1 mr-2 h-5 w-5"
          aria-hidden="true"
        ></DocumentTextIcon>
        Generate Default Document: {defaultDocumentTemplate.name}
      </button>
    </div>}
    <div className="mb-4 flex px-3">
      <button
          onClick={() => {
            API.matters.tasks
              .updateStatus(params.matter_id, params.task_id, {...data, postbox_print: true})
              .then(() => history.push(
                `/matters/${params.matter_id}/tasks/${params.task_id}`
              ))
              // .then(() => setMountLoading(true))
              .catch((e) => CommonFunctions.handleError(e))
          }}
          className="text-lg flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-green-500 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
        <PrinterIcon
          className="-ml-1 mr-2 h-5 w-5"
          aria-hidden="true"
        ></PrinterIcon>
        Print at Postbox
      </button>
    </div>
    
    {/* </> : data.step_task && data.step_task.stepType === "Email" && !data.complete ? <> */}

    <div className="mb-4 flex px-3">
      <Button
        label="Document"
        icon={DocumentSearchIcon}
        className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
        onClick={() => {
          setDocumentWizard(true);
          setDocumentWizardStep(1);
        }} />
    </div>

    </> :
    <Button
      colour={task.complete ? "alert" : "positive"}
      label={task.complete ? "(Dev)Mark Incomplete" : "Mark Completed"}
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id)
          // .then(() => setMountLoading(true))
          .then(() =>  history.push(`/matters/${params.matter_id}/tasks`))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg mr-5"
      icon={task.complete ? XIcon : CheckIcon}
    />
  }else{
    /* let documentTemplatesFiltered = documentTemplates.filter(e => e.recipient && e.recipient.name == documentTemplatesType);

    let documentTemplatesFiltered = documentTemplates.filter(e => 
      e.recipient && 
      Array.isArray(e.recipient) &&
      e.recipient.some(recipient => recipient.label === documentTemplatesType) &&
      !e.global_template
    );
    //*/


    completeButtons = task.area_task && task.area_task.taskType === "YesNoOption" && !task.complete ? <>
    <Button
      colour="warning"
      label="Mark Yes"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "Yes"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg"
      icon={CheckIcon}
    />
    <Button
      colour="warning"
      label="Mark No"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "No"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-4 text-lg"
      icon={XIcon}
    />
    </> :
    <>

      <div className="mb-4 flex px-3">
        <Button
          label="Document"
          icon={DocumentSearchIcon}
          className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          onClick={() => {
            setDocumentWizard(true);
            setDocumentWizardStep(1);
          }} />
      </div>

        <div className="mb-4 flex px-3">
          <button
              onClick={() =>
                API.matters.tasks
                  .updateStatus(params.matter_id, params.task_id)
                  // .then(() => setMountLoading(true))
                  .then(() => history.push(`/matters/${params.matter_id}/tasks`))
                  .catch((e) => CommonFunctions.handleError(e))
              }
              className={`text-lg flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray ${task.complete ? 'bg-red-500' : 'bg-green-500'} px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
            >
              {task.complete ? <XIcon
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            ></XIcon> : <CheckIcon
            className="-ml-1 mr-2 h-5 w-5"
            aria-hidden="true"
          ></CheckIcon>}
            {task.complete ? "Mark Incomplete(Dev Only)" : "Mark Completed."}
          </button>
        </div>
    </>
    
  }

  const handleSaveData = (data, field) => {
    // setData(a => {
    setTask(a => {
      return {
        ...a,
        [field]: data
      }
    })
  }

  const previewContent = () => {

    let html = documentHeader + "<hr/>";
    html += documentContent + "<hr/>";
    html += documentFooter + "<hr/>";

    return html;

  }

  const GeneratingDocumentModal = () => { 
    return(
      <Transition.Root show={generatingDocument} as={Fragment}>
      <Dialog as="div" className="relative z-90" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-50">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center w-full ">
                      <Dialog.Title as="h1" className="text-lg font-medium text-gray-900 text-center ">
                        {generatingDocumentError && "Error"} Generating Document
                      </Dialog.Title>
                      <div className="mt-5">
                        {generatingDocumentError && <div style={{display: "flex", justifyContent: "center"}}>
                          <BiErrorAlt size="5em" color="red"/>
                        </div>}
                        {!generatingDocumentError && <h1><Loader/></h1>}
                        <p className="text-lg font-medium text-gray-900 text-center" style={generatingDocumentError ? {maxWidth: "500px", margin: "0 auto"} : {}}>
                          {generatingDocumentError ? generatingDocumentErrorMessage : "Please wait whilst the document is being generated."}
                        </p>
                        {generatingDocumentError && <p><br/>Please adjust Document and try again, if the error is still occuring please contact support.</p>}
                        {generatingDocumentError && <p><br/>
                        
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setGeneratingDocumentError(false);
                            setGeneratingDocumentErrorMessage("");
                            setGeneratingDocument(false);
                          }}
                        >
                          Close
                        </button>
                        
                        </p>}
                        <p></p>
                      </div>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  const documentModal = () => { 

    let template = documentTemplates.filter(e => e._id === openDocumentModal);

    return(
      <Transition.Root show={openDocumentModal != null && !generatingDocument} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenDocumentModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {template[0] && (template[0].document_type == "simple" ? "Document: " : "Form: ")}{template[0] && template[0].name}
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          {template[0] && template[0].document_type != "form" &&
                          <>
                          <label for="dateVerbose"><strong>Date:</strong></label>
                          <DatePicker
                            name="dateVerbose"
                            onChange={(date) => 
                              setDateVerbose(date)
                            }
                            label="Date"
                            selected={dateVerbose ? new Date(dateVerbose) : new Date()}
                            showPopperArrow={false}
                            shouldCloseOnSelect={true}
                            dateFormat="dd-MM-yy"
                            className={`relative w-full  p-2 rounded-lg bg-gray-100 border border-gray-300`}
                          />
                          <label for="parties"><strong>Parties:</strong></label>
                          <Select 
                            isMultiple
                            value={Array.isArray(parties) ? parties : []}
                            onChange={(value) => {
                              setParties(value)}
                            }
                            options={matter.correspondents.filter(c => documentReceipts.includes(c.correspondentType)).map(c => {
                              return {label: c.name, value: c._id}
                            })}
                          />
                          <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowHeader(!showHeader)}>
                            Header: <ArrowDownIcon className={`w-4 inline ${showHeader ? "" : "rotate-180"}`} />
                            <span className="block text-xs text-slate-400">Click to {showHeader ? "Hide" : "Show"}</span>
                          </h1>
                            
                            {showHeader &&

                              <HtmlEditor
                                content={documentHeader}
                                templates={headerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentHeader(d)}
                              />
                            }

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2">
                              Content:
                            </h1>

                            <HtmlEditor
                              content={documentContent}
                              options={{
                                height: 400,
                              }}
                              onContentUpdate={(d) => setDocumentContent(d)}
                            />

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowFooter(!showFooter)}>
                              Footer: <ArrowDownIcon className={`w-4 inline ${showFooter ? "" : "rotate-180"}`} />
                              <span className="block text-xs text-slate-400">Click to {showFooter ? "Hide" : "Show"}</span>
                            </h1>

                            {showFooter &&
                              <HtmlEditor
                                content={documentFooter}
                                templates={footerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentFooter(d)}
                              />

                            }

                          </>
                            
                          }

                          {template[0] && template[0].document_type == "form" &&
                          
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Type
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Content
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {template[0].document_fields.length ? (
                                template[0].document_fields.filter(f => f.type === "PDFTextField" || f.type === "PDFCheckBox").map((field, index) => {
          
                                  let fields = template[0].document_fields_map;
                                  let map = fields.find(f => f.field == field.name);

                                  return(
                                  <tr key={"field_" + index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {field.type}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {field.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {field.type == "PDFTextField" &&
                                        <Input 
                                          value={map?.content || ""}
                                          onChange={(d) => {
                                            map.content = d.target.value;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.value 
                                              }
                                            }), "document_fields_map")}
                                          }
                                        
                                        />}

                                        {field.type == "PDFCheckBox" &&
                                        <Input 
                                          type="checkbox"
                                          checked={map?.content || false}
                                          onChange={(d) => {
                                            map.content = d.target.checked;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.checked
                                              }
                                            }), "document_fields_map")}
                                          }
                                        /> }
                                      
                                      
                                    </td>
                                  </tr>);
                                            
                                }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                  >
                                    There are no fields to display.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          }

                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>  

                        {/*template[0] && template[0].document_type != "form" &&
                          <p className="text-sm text-gray-500 sm:col-span-1">
                            <h1 className="text-med font-medium leading-6 text-gray-900 mb-2">Email Preview:</h1>
                            <div style={{maxHeight: "550px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                          </p> */}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/*  
                  <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Email Document
                  </button>*/}
                  <button
                    disabled={generatingDocument || !parties.length > 0}
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${generatingDocument || !parties.length > 0 ? "cursor-not-allowed opacity-50" : ""}`}
                    onClick={() => {
                      setGeneratingDocument(true);
                      API.matters.tasks.documentGenerate(params.matter_id, params.task_id, {
                        template:openDocumentModal,
                        document_fields_map: task.document_fields_map,
                        content: documentContent,
                        header_content: documentHeader,
                        footer_content: '<div style="width: 100%;">'+ documentFooter + "</div>",
                        date_verbose: dateVerbose,
                        parties: parties,
                      }).then((res) => {
                        if(res.success) {
                          setGeneratingDocument(false);
                          setOpenDocumentModal(null);

                          // window.open(
                          //   _appConfig.api_server + res.file,
                          //   "_blank",
                          //   "noopener"
                          // );

                          if (res.correspondent_files && res.correspondent_files.length > 0) {
                            res.correspondent_files.map((file, index) => {
                                window.open(
                                    _appConfig.api_server + file.fileUrl,
                                    "_blank_" + index,
                                    "noopener"
                                );
                            });
                          }

                          /*

                          let correspondent_files = res.correspondent_files.length > 0 ? res.correspondent_files.map(f => {
                            return f.file
                          }) : [];
                          console.log("🚀 ~ documentModal ~ correspondent_files:", correspondent_files)*/

                          API.matters.tasks.update(params.matter_id, params.task_id, {document: res.file_id, correspondent_files: res.correspondent_files}).then(() => { setMountLoading(true); });
                          /*
                          API.matters.tasks
                          .updateStatus(params.matter_id, params.task_id, {document: res.file_id})
                          .then(() => setMountLoading(true))
                          .catch((e) => CommonFunctions.handleError(e))*/
                        }else{
                          setGeneratingDocumentError(true);
                          setGeneratingDocumentErrorMessage(res.message);
                        }
                      })
                    }}
                  >
                    {generatingDocument ? <>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </> : "Generate Document(s)"}
                    
                  </button>
                  <button
                    disabled={generatingDocument || !parties.length > 0}
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-action px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${generatingDocument || !parties.length > 0 ? "cursor-not-allowed opacity-50" : ""}`}
                    onClick={() => {
                      setGeneratingDocument(true);
                      API.matters.tasks.documentGenerate(params.matter_id, params.task_id, {
                        template:openDocumentModal,
                        document_fields_map: task.document_fields_map,
                        content: documentContent,
                        header_content: documentHeader,
                        footer_content: '<div style="width: 100%;">'+ documentFooter + "</div>",
                        date_verbose: dateVerbose,
                        parties: parties,
                      }).then((res) => {
                        if(res.success) {
                          
                          // window.open(
                          //   _appConfig.api_server + res.file,
                          //   "_blank",
                          //   "noopener"
                          // );

                          if (res.correspondent_files && res.correspondent_files.length > 0) {
                            res.correspondent_files.map((file, index) => {
                                window.open(
                                    _appConfig.api_server + file.fileUrl,
                                    "_blank_" + index,
                                    "noopener"
                                );
                            });
                          }

                        setGeneratingDocument(false);

                        }else{
                          setGeneratingDocumentError(true);
                          setGeneratingDocumentErrorMessage(res.message);
                        }
                      })
                    }}
                  >
                    {generatingDocument ? <>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </> : "Preview(s)"}
                    
                  </button>
                  
                  {/*  
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpenDocumentModal(false)
                      window.open(
                        _appConfig.api_server + openDocumentFile,
                        "_blank",
                        "noopener"
                      )
                    }}
                  >
                    Download Document
                  </button>*/}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  const emailModal = () => { 

    let template = emailTemplates.filter(e => e._id === openEmailModal);

    return(
      <Transition.Root show={openEmailModal != null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenEmailModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <MailIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Email: {template[0] && template[0].name}
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-2">

                          <HtmlEditor
                            content={documentContent}
                            options={{
                              height: 400,
                            }}
                            onContentUpdate={(d) => setDocumentContent(d)}
                          />

                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>
                        <p className="text-sm text-gray-500 sm:col-span-1">
                          Preview:
                          <div style={{maxHeight: "550px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {

                      API.matters.tasks
                      .updateStatus(params.matter_id, params.task_id, 
                        {
                        sendEmailTemplate: openEmailModal, 
                        email_content: documentContent
                      })
                      .then(() => {setMountLoading(true); setOpenEmailModal(null)})
                      .catch((e) => CommonFunctions.handleError(e))

                    } }
                  >
                    Send to Postbox
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenEmailModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  const progressBar = () => {

    const steps = [
      { id: 1, name: 'Type', description: documentAreaType ? "Selected: " + documentAreaType : "Not Selected!", status: documentWizardStep == 1 ? "current" : documentAreaType ? "complete" : "upcoming" },
      { id: 2, name: 'Area', description: documentTemplatesTypeParent ? "Selected: " + documentTemplatesTypeParent.name : "Not Selected!", status: documentWizardStep == 2 ? "current" : documentTemplatesTypeParent ? "complete" : "upcoming" },
      { id: 3, name: 'Sub Area', description: documentTemplatesType ? "Selected: " + documentTemplatesType : "Not Selected!", status: documentWizardStep == 3 ? "current" : documentTemplatesType ? "complete" : "upcoming" },
      { id: 4, name: 'Template', description: "Not Selected!", status: documentWizardStep == 4 ? (documentAreaType == "law" && !documentTemplates.length > 0 ? "none" : "current") : "upcoming" },
    ]
    
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    return (
      <div className="lg:border-b lg:border-t lg:border-gray-200">
        <nav aria-label="Progress" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            {steps.map((step, stepIdx) => (
              <li key={`${stepIdx} _${step.id}`} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
                    stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
                    'overflow-hidden border border-gray-200 lg:border-0',
                  )}
                >
                  {step.status === 'complete' ? (
                    <a onClick={() => setDocumentWizardStep(step.id)} className="group">
                      <span
                        aria-hidden="true"
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'flex items-start px-6 py-5 text-sm font-medium',
                        )}
                      >
                        <span className="shrink-0">
                          <span className="flex size-10 items-center justify-center rounded-full bg-positive">
                            <CheckIcon className="w-10 text-white" />
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium">{step.name}</span>
                          <span className="text-sm font-medium text-gray-500">{step.description}</span>
                        </span>
                      </span>
                    </a>
                  ) : step.status === 'current' ? (
                    <a onClick={() => setDocumentWizardStep(step.id)} aria-current="step">
                      <span
                        aria-hidden="true"
                        className="absolute left-0 top-0 h-full w-1 bg-primary lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'flex items-start px-6 py-5 text-sm font-medium',
                        )}
                      >
                        <span className="shrink-0">
                          <span className="flex size-10 items-center justify-center rounded-full border-2 border-primary">
                            <span className="text-primary">{step.id}</span>
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-primary">{step.name}</span>
                          <span className="text-sm font-medium text-secondary">{step.description}</span>
                        </span>
                      </span>
                    </a>
                  ) : step.status === 'none' ? (
                    <a onClick={() => setDocumentWizardStep(step.id)} aria-current="step">
                      <span
                        aria-hidden="true"
                        className="absolute left-0 top-0 h-full w-1 bg-primary lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'flex items-start px-6 py-5 text-sm font-medium',
                        )}
                      >
                        <span className="shrink-0">
                          <span className="flex size-10 items-center justify-center rounded-full bg-alert">
                            <ExclamationCircleIcon className="w-10 text-white" />
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-primary">{step.name}</span>
                          <span className="text-sm font-medium text-alert">Category has no templates!</span>
                        </span>
                      </span>
                    </a>
                  ) : (
                    <a className="group">
                      <span
                        aria-hidden="true"
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? 'lg:pl-9' : '',
                          'flex items-start px-6 py-5 text-sm font-medium',
                        )}
                      >
                        <span className="shrink-0">
                          <span className="flex size-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-gray-500">{step.id}</span>
                          </span>
                        </span>
                        <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-gray-500">{step.name}</span>
                          <span className="text-sm font-medium text-gray-500">{step.description}</span>
                        </span>
                      </span>
                    </a>
                  )}

                  {stepIdx !== 0 ? (
                    <>
                      {/* Separator */}
                      <div aria-hidden="true" className="absolute inset-0 left-0 top-0 hidden w-3 lg:block">
                        <svg
                          fill="none"
                          viewBox="0 0 12 82"
                          preserveAspectRatio="none"
                          className="size-full text-gray-300"
                        >
                          <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    )
  }

  const showDocumentWizard = () => {

    let documentTemplatesFiltered = documentTemplates;

    let globalTemplates = documentTemplates.filter(e => 
      e.global_template
    );

    const closeModal = () => {
      setDocumentWizard(false);
      setDocumentAreaType(null);
      setDocumentTemplatesType(null);
      setDocumentTemplatesTypeParent(null);
    }

    return (
      <Transition.Root show={documentWizard} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mr-10 ml-10"> {/* w-full */}
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Document Wizard
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          {progressBar()}
                        </p>
                        {documentWizardStep == 1 &&
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          <h2>1. Type Selection:</h2>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50">
                                <DocumentSearchIcon
                                  className="-ml-1 mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Select Type
                              </Menu.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      <div
                                        onClick={() => {setDocumentAreaType("law"); setDocumentWizardStep(2)}}
                                        className={
                                          "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                        }
                                      >
                                        Area of Law Templates
                                      </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <div
                                        onClick={() => {setDocumentAreaType("global"); setDocumentWizardStep(4)}}
                                        className={
                                          "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                        }
                                      >
                                        Global Templates
                                      </div>
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                        </p>}
                        {documentWizardStep == 2 &&
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          <h2>2. Area Selection:</h2>
                            <SelectZero 
                              options={documentTemplatesTypes.filter(e => e.archived != true && !e.parent).map((template) => {
                                return {
                                  text: `${template.name} (${template.shortcode})`,
                                  value: template._id
                                }
                              })}
                              onChange={(e) => {

                                let t = documentTemplatesTypes.filter(a => a._id === e.target.value);

                                setDocumentTemplatesTypeParent(t[0]);
                                setDocumentTemplatesType(null);
                                setDocumentWizardStep(3);
                              }}
                            />
                        </p>}
                        {documentWizardStep == 3 &&
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          <h2>3. Sub Area Selection:</h2>
                            <SelectZero 
                              options={
                                [{text: `${documentTemplatesTypeParent.name} (${documentTemplatesTypeParent.shortcode})`, value: documentTemplatesTypeParent._id}].concat(documentTemplatesTypes.filter(e => e.archived != true && e.parent && e.parent._id == documentTemplatesTypeParent._id).map((template) => {
                                return {
                                  text: `${template.name} (${template.shortcode})`,
                                  value: template._id
                                }
                              }))}
                              onChange={(e) => {
                                let t = documentTemplatesTypes.filter(a => a._id === e.target.value);
                                setDocumentTemplatesType(t[0].name); 
                                setDocumentWizardStep(4);
                              }}
                            />
                        </p>}
                        {documentWizardStep == 4 &&
                        <p className="text-sm text-gray-500 sm:col-span-3">
                          <h2>4. Template Selection:</h2>
                            {documentTemplatesType && documentAreaType === "law" ?
                            <div className="mb-4 flex px-3">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                {documentTemplatesFiltered.length > 0 ? <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                  <DocumentTextIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  Select {documentTemplatesType} Template:
                                </Menu.Button>

                                :

                                <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 bg-alert px-4 py-2 text-sm font-medium text-white shadow-sm cursor-not-allowed">
                                  <XIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  {documentTemplatesType} Has No Templates!
                                </Menu.Button>}

                                {documentTemplatesFiltered.length > 0 &&

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {documentTemplatesFiltered.map((template) => {
                                        return <Menu.Item>
                                        <div
                                          onClick={() => {
                                            setDocumentReceipts(template.recipients)
                                            setOpenDocumentModal(template._id)
                                            setDocumentContent(template.content);
                                            setDocumentHeader(template.header_content);
                                            setDocumentFooter(template.footer_content);
                                            setData({
                                              ...data,
                                              document_fields_map: template.document_fields
                                            });
                                            setDocumentWizard(false)
                                            /*
                                          API.matters.tasks
                                            .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                            .then(() => setMountLoading(true))
                                          .catch((e) => CommonFunctions.handleError(e))}*/ }}
                                          className={
                                            "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                          }
                                        >
                                          {template.name}
                                        </div>
                                      </Menu.Item>
                                      })}
                                    </div>
                                  </Menu.Items>
                                </Transition>}
                              </Menu>
                            </div> : documentAreaType === "global" &&
                            <div className="mb-4 flex px-3">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                <DocumentTextIcon
                                  className="-ml-1 mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Select Global Template:
                              </Menu.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    {globalTemplates.map((template) => {
                                      return <Menu.Item>
                                      <div
                                        onClick={() => {
                                          setOpenDocumentModal(template._id)
                                          setDocumentContent(template.content);
                                          setDocumentHeader(template.header_content);
                                          setDocumentFooter(template.footer_content);
                                          setData({
                                            ...data,
                                            document_fields_map: template.document_fields
                                          });
                                          setDocumentWizard(false)
                                          /*
                                        API.matters.tasks
                                          .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                          .then(() => setMountLoading(true))
                                        .catch((e) => CommonFunctions.handleError(e))}*/ }}
                                        className={
                                          "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                        }
                                      >
                                        {template.name}
                                      </div>
                                    </Menu.Item>
                                    })}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div> }

                        </p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  return (
    <div className="">
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}`}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Task - #${task.description}`, href: `/matters/${matter._id}/tasks/${params.task_id}`, current: false },
        { name: `Edit`, href: '#', current: true },
      ]} />}
      <Segment className="mt-4">
        {GeneratingDocumentModal()}
        {showDocumentWizard()}
        {documentModal()}
        {emailModal()}
        <div className="">
        <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">Run Task</span>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {((task.area_step && task.area_step.selectCourt) || task.additional_task) && selectCourt}
              {templatesLoading ? <Loader /> : completeButtons}
              
            </span>
          </h3>
          <p style={{textAlign: "right"}}>Note: Print &amp; Send Email marks the task as completed!</p>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <Group className="py-2">
                <TextArea
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                />
              </Group>
              <Group className="py-2">
                <Input
                  // disabled={timer}
                  disabled={true}
                  type="number"
                  min="0"
                  // incrementBy="6"
                  label="Billable Time"
                  name="time"
                  value={data.time / 6}
                  onChange={onChange}
                />
                <div>
                  <label className="block text-sm font-medium text-gray-700">Timer</label>
                  <Button
                    className={`text-center w-full h-10 mt-auto mb-auto mr-2 ${timer && "animate-pulse"}`}
                    colour={timer ? "sidebarTimerActive" : "sidebarTimer"}
                    // label={timerClock}
                    label={`${(
                      "0" + Math.floor((timerClock / 3600000) % 24)
                    ).slice(-2)}:${(
                      "0" + Math.floor((timerClock / 60000) % 60)
                    ).slice(-2)}:${(
                      "0" + Math.floor((timerClock / 1000) % 60)
                    ).slice(-2)}`}
                    onClick={() => {
                      setTimer(!timer);
                    }}
                    disabled={false}
                    icon={timer ? StopIcon : PlayIcon}
                  />
                </div>
                
                <Dropdown
                  label="Fee Earner"
                  name="assigned"
                  value={data.assigned}
                  onChange={(e) => {
                    const user = userList.find(
                      (item) => item.value === e.target.value
                    );
                    setData({ ...data, [e.target.name]: e.target.value, currentRate: !data.defaultAdminRate ? user.rate : data.currentRate });
                  }}
                  options={userList}
                />
                <DateSelect
                  label="Due Date / Completed Date"
                  name="due_date"
                  value={data.due_date}
                  onChange={onDateChange}
                />
                <div>

                  <Input
                    name="cost"
                    label="Cost (£) ((time / 6) * (rate / 60))"
                    disabled
                    value={(
                      (data.time / 6) * (data.currentRate ? parseFloat(
                        matter.billing_method === "contingency" 
                            ? data.currentRate * ((matter.contingency_percentage / 100) + 1) 
                            : data.currentRate
                    ) / 60 : 0)
                    ).toFixed(2)}
                  />
                  <p>{data.rateDetails} @ £{parseFloat(
                        matter.billing_method === "contingency" 
                            ? data.currentRate * ((matter.contingency_percentage / 100) + 1) 
                            : data.currentRate
                    ).toFixed(2) } {matter.billing_method === "contingency" && `(£${data.currentRate} + contingency of ${matter.contingency_percentage}%)` } p/h</p>

                </div>
              </Group>
              <Toggle
                className="my-2"
                label="Is Billable?"
                enabled={data.isBillable}
                onChange={() =>
                  setData({
                    ...data,
                    isBillable: data.isBillable ? !data.isBillable : true,
                  })
                }
              />
              <Toggle
                className="my-2"
                label="Charge Default Admin Rate"
                enabled={data.defaultAdminRate}
                onChange={() =>
                  {
                    const user = userList.find(
                      (item) => item.value === data.assigned
                    );

                    let rate = 0;
                    let rateDetails = "";

                    if(data.defaultAdminRate){
                      rate = user.rate
                      rateDetails = user.rateDetails;
                    }else{
                      rate = defaultAdminRate;
                      rateDetails = "Default Admin Rate";
                    }

                    setData({
                      ...data,
                      defaultAdminRate: !data.defaultAdminRate,
                      currentRate: rate,
                      rateDetails,
                    });
                  }
                }
              />
              <div className="pt-2 flex justify-between">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() =>
                    history.push(
                      `/matters/${params.matter_id}/tasks/${params.task_id}`
                    )
                  }
                  icon={XIcon}
                />
                <Button
                  colour="positive"
                  label="Update"
                  onClick={() => {
                    API.matters.tasks
                      .update(params.matter_id, params.task_id, {
                        ...data,
                        currentRate: parseFloat(
                          matter.billing_method === "contingency" 
                              ? data.currentRate * ((matter.contingency_percentage / 100) + 1) 
                              : data.currentRate
                      )
                      })
                      .then(() =>
                        history.push(
                          `/matters/${params.matter_id}/tasks/${params.task_id}`
                        )
                      )
                      .catch((e) => CommonFunctions.handleError(e));
                  }}
                  icon={CheckIcon}
                />
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Edit), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);

