import React, { useState, useEffect } from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../plugins/clio_auth/_components/authenticationReceiver";
import { Button, Dropdown, SelectTwo, SelectZero } from "../../../components/Forms";
import API from "../../../api";
import toast from "react-hot-toast";

const contactTypes = [
  { key: 0, text: "Person", value: "Person" },
  { key: 1, text: "Company", value: "Company" },
];

const AddForm = ({ contact = {}, onSubmit, onCancel, auth }) => {
  const [data, setData] = useState({
    contact: "",
    description: "",
    // practice_area: 885229,
    practice_area: null,
    client_reference: null,
    location: null,
    responsible_attorney: "",
    originating_attorney: "",
    billing_method: "fixed",
    contingency_percentage: 0,
    fixed_amount: 0,
    xero_send: "manual",
    billable: true,
    areaTask: -1
  });
  const [mountLoading, setMountLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [clioUsers, setClioUsers] = useState([]);
  const [practiceArea, setPracticeArea] = useState([]);
  const [areaTasks, setAreaTasks] = useState([{text: "None", value: -1}]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // if (mountLoading && auth.user.email) {
    if (mountLoading) {

      let theData = data;

      API.contacts.getAllListed().then((contacts) => {
          // contacts.sort((a, b) => a.name.localeCompare(b.name));

          contacts.sort((a, b) => {

            let aName = a.name ? a.name : a.first_name + " " + a.last_name;
            let bName = b.name ? b.name : b.first_name + " " + b.last_name;

            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          });

          setContacts(
            contacts.map((c, i) => {
              return { key: i, text: c.name ? c.name : c.first_name + " " + c.last_name, value: c._id };
            })
          )
        }
      );

      API.settings.area_tasks.getAll().then(res => {
        const tasksBuilder = res.map((a) => {
          return {
            text: a.area,
            value: a._id,
          };
        });

        setAreaTasks(areaTasks.concat(tasksBuilder));
      })

      API.settings.doc_types().list().then((res) => {
        // const practice_areas = res.map((a) => {
        const practice_areas = res.filter(a => a.archived !== true).map((a) => {
          return {
            text: `${a.name} ${a._clioId ? "(in Clio)" : ""}`,
            // value: a.id,
            value: a._id,
          };
        });

        setPracticeArea(practice_areas);

        /*

        if (
          practice_areas.find((item) => item.value === 885229) !== undefined
        ) {
          // setData({ ...data, practice_area: 885229 });
        } */
      });

      API.users.getAll().then((res) => {
        setClioUsers(
          res.users.map((a) => {
           if(a.email == auth.user.email){
              
              theData.responsible_attorney = a._clioId;
              // setData({ ...data, responsible_attorney: a.id });
            }

            return {
              text: a.name,
              value: a._clioId,
            };
          })
        );

        setData({ ...data, 
          responsible_attorney: theData.responsible_attorney });

      });

      setMountLoading(false);
    }
  });

  useEffect(() => {
    if (contact._id) {
      setData(contact);
    }
  }, [contact]);

  const onChange = (e) => {
    setErrors({});

    let newData = {...data, [e.target.name]: e.target.value};

    if(e.target.name === "billing_method" && e.target.value !== "hourly"){
      newData.billable = false;
    }

    setData(newData);
  };

  const handleSubmit = () => {

    setMountLoading(true);

    let err = errors;

    if(!data.contact){
      err.contact = "Please enter a contact"
    }

    if(!data.practice_area){
      err.contact = "Please select a Practice Area"
    }

    setErrors(err);

    // if(!errors.contact && !errors.practice_area){
    if(!errors.contact){
      let contact = contacts.find(e => e.value == data.contact)
      // let practice = practiceArea.find(e => e.value == data.practice_area)
      // data.description = `${contact ?  contact.text : ""} - ${practice ? practice.text : ""}`;
      data.description = `${contact ?  contact.text : ""}`;
      onSubmit(data);
    }else{
      toast.error("Matter not created, missing data!");
    }

  };

  return (
    <div className={"mt-4"}>
      <Segment padding="px-4 mb-5">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Matter
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Select a Contact &amp; fill out matter details
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="contact"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact<span className="text-red-600">*</span>
                      </label>
                      <SelectZero
                        placeholder="Please Select Contact"
                        name="contact"
                        options={contacts}
                        // className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.contact}
                        onChange={onChange}
                      />
                      <p className="text-red-600">{errors.contact}</p>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder=""
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="practice_area"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Area of Law
                      </label>
                      <SelectTwo
                        placeholder="Please Select Area"
                        field="practice_area"
                        options={practiceArea}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.practice_area}
                        onChange={onChange}
                      />
                      <p className="text-red-600">{errors.practice_area}</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="practice_area"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Area of Law Workflow<span className="text-red-600">*</span>
                      </label>
                      <SelectTwo
                        placeholder="Please Select Area"
                        field="areaTask"
                        options={areaTasks}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.areaTask}
                        onChange={onChange}
                      />
                      {/* <p>Note: This is not available on Edit Matter as on creation the Workflow tasks are generated and saved against the Matter!</p> */}
                      <p className="text-red-600">{errors.areaTask}</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="client_reference"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Client Reference
                      </label>
                      <input
                        type="text"
                        name="client_reference"
                        id="client_reference"
                        onChange={onChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="client_reference"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Clio Reference (Auto Generated ##client . ##matter number)
                      </label>
                      <input
                        disabled
                        type="text"
                        name="client_reference"
                        id="client_reference"
                        onChange={onChange}
                        value={"123.123"}
                        className="mt-1 block w-full rounded-md bg-gray-200 text-gray-500 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    {/*  
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </label>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        onChange={onChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>*/}
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="responsible_attorney"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fee Earner
                      </label>
                      <SelectTwo
                        placeholder="Please Select Fee Earner"
                        field="responsible_attorney"
                        options={clioUsers}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.responsible_attorney}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="originating_attorney"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Supervisor
                      </label>
                      <SelectTwo
                        placeholder="Please Select Supervisor"
                        field="originating_attorney"
                        options={clioUsers}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.originating_attorney}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-4 sm:col-span-3">
                      <Dropdown
                        label={"Billing Method"}
                        name={"billing_method"}
                        value={data.billing_method ? data.billing_method : ""}
                        onChange={onChange}
                        options={[
                          {key: 1, text: "Hourly", value: "hourly"},
                          {key: 2, text: "Fixed", value: "fixed"},
                          {key: 3, text: "Contingency", value: "contingency"},
                        ]}
                      />
                    </div>
                    <div className="col-span-4 sm:col-span-3">
                      <label
                        htmlFor="billable"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Billable
                      </label>
                      <input
                          id="billable"
                          name="billable"
                          type="checkbox"
                          onChange={() => setData({ ...data, billable: !data.billable })}
                          checked={data.billable}
                          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-indigo-500"
                      />
                    </div>
                    {data.billing_method === "contingency" &&
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="contingency_percentage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contingency Percentage 
                      </label>
                      <input
                        type="number"
                        name="contingency_percentage"
                        id="contingency_percentage"
                        onChange={onChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>}
                    {data.billing_method === "fixed" &&
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="fixed_amount"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fixed 
                      </label>
                      <input
                        type="number"
                        name="fixed_amount"
                        id="fixed_amount"
                        onChange={onChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>}
                    <div className="col-span-4 sm:col-span-3">
                      <Dropdown
                        label={"Xero Invoices"}
                        name={"xero_send"}
                        value={data.xero_send ? data.xero_send : ""}
                        onChange={onChange}
                        options={[
                          {key: 1, text: "Instant - Sends to Xero as it comes in", value: "instant"},
                          {key: 2, text: "Manual - No Automatic sending", value: "manual"},
                          {key: 3, text: "Weekly Joint - Joins Invoices together and sends weekly", value: "weekly_joint"},
                          {key: 4, text: "Monthly Joint - Joins Invoices together and sends at end of month", value: "monthly_joint"},
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button
                    onClick={handleSubmit}
                    label="Add"
                    colour="positive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default ChainedContext(AddForm, [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ]
]);
