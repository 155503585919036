import React, {useEffect, useState} from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import Segment from "../../../components/Segment";
import { Button} from "../../../components/Forms";
import Loader from "../../../components/Loader";
import dayjs from "dayjs";
import { CheckIcon, LinkIcon, PaperClipIcon, PrinterIcon, XIcon } from "@heroicons/react/solid";
import { BiRun } from "react-icons/bi";
import _appConfig from "../../../_appConfig";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";

export default function View() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const [data, setData] = useState({});
  const [matter, setMatter] = useState(null);

  const [mountLoading, setMountLoading] = useState(true);

  const [matterDebtors, setMatterDebtors] = useState([]);
  const [letterHeaders, setLetterHeaders] = useState([]);


  useEffect(() => {
    if (mountLoading) {

      if(!matter){
        API.matters.get(params.matter_id).then((res) => {
          console.log("🚀 ~ API.matters.get ~ res:", res)
          setMatter(res);
        })
      }

      API.settings.letter_headers().list().then((res) => {
        setLetterHeaders(res);
      });

      API.matters.tasks
        .get(params.matter_id, params.task_id)
        .then((res) => {
          setData(res);

          API.matters.debtors
          .getAll(params.matter_id)
          .then((res2) => {
            setMatterDebtors(res2);
          })
          .catch((e2) => CommonFunctions.handleError(e2));
            
          setMountLoading(false);
          
        })
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [mountLoading, params]);


  let completeButtons;

  if(data.isStep){
    
    completeButtons = data.area_step && data.area_step.stepType === "YesNoOption" && !data.complete ? <>
    <Button
      colour="warning"
      label="Mark Yes"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "Yes"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg"
      icon={CheckIcon}
    />
    <Button
      colour="warning"
      label="Mark No"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "No"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-4 text-lg"
      icon={XIcon}
    />
    </>: data.area_step && (data.area_step.stepType === "UploadDocument" || data.area_step.stepType === "GenerateDocument" || data.area_step.stepType === "SendEmail") && !data.complete ? <>

    <div className="mb-4 flex px-3">
      <button
          onClick={() => {
            API.matters.tasks
              .updateStatus(params.matter_id, params.task_id, {postbox_print: true})
              .then(() => setMountLoading(true))
              .catch((e) => CommonFunctions.handleError(e))
          }}
          className="text-lg flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-green-500 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
        <PrinterIcon
          className="-ml-1 mr-2 h-5 w-5"
          aria-hidden="true"
        ></PrinterIcon>
        Print at Postbox
      </button>
    </div>
    </> :
    <Button
      colour={data.complete ? "alert" : "positive"}
      label={data.complete ? "(Dev)Mark Incomplete" : "Mark Completed"}
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id)
          // .then(() => setMountLoading(true))
          .then(() =>  history.push(`/matters/${params.matter_id}/tasks`))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg mr-5"
      icon={data.complete ? XIcon : CheckIcon}
    />
  }else{

    completeButtons = data.area_task && data.area_task.taskType === "YesNoOption" && !data.complete ? <>
    <Button
      colour="warning"
      label="Mark Yes"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "Yes"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-auto text-lg"
      icon={CheckIcon}
    />
    <Button
      colour="warning"
      label="Mark No"
      onClick={() =>
        API.matters.tasks
          .updateStatus(params.matter_id, params.task_id, {YesNoOption: "No"})
          .then(() => setMountLoading(true))
          .catch((e) => CommonFunctions.handleError(e))
      }
      className="ml-4 text-lg"
      icon={XIcon}
    />
    </> :
    <>
        <div className="mb-4 flex px-3">
          <button
              onClick={() =>
                API.matters.tasks
                  .updateStatus(params.matter_id, params.task_id)
                  // .then(() => setMountLoading(true))
                  .then(() => history.push(`/matters/${params.matter_id}/tasks`))
                  .catch((e) => CommonFunctions.handleError(e))
              }
              className={`text-lg flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray ${data.complete ? 'bg-red-500' : 'bg-green-500'} px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
            >
              {data.complete ? <XIcon
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            ></XIcon> : <CheckIcon
            className="-ml-1 mr-2 h-5 w-5"
            aria-hidden="true"
          ></CheckIcon>}
            {data.complete ? "Mark Incomplete(Dev Only)" : "Mark Completed."}
          </button>
        </div>
    </>
    
  }

  let days_lba = data.matter && data.matter.contact && data.matter.contact.type === "Company" ? 14 : 30;
  let LBADue = data.matter && dayjs(data.matter.open_date).add(days_lba, 'day')

  return (
    <div className="">
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Task - #${data.description}`, href: '#', current: true },
      ]} />}
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Task.</span>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {completeButtons}
              <Button
                colour="action"
                label="Run"
                icon={BiRun}
                onClick={() => history.push(`${match.url}/edit`)}
                className="text-white relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
            </span>
          </h3>
          <p style={{textAlign: "right"}}>Note: Print &amp; Send Email marks the task as completed! Document Generation moved inside of Run task!</p>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Details
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.description}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Due Date / Completed Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.due_date && dayjs(data.due_date).format("DD/MM/YYYY")}
                    </dd>
                  </div>
                  {/*  
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      LBA Due
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {LBADue && dayjs(LBADue).format("DD/MM/YYYY")}
                    </dd>
                  </div>*/}
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Task Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.area_task ? data.area_task.taskType : data.area_step ? data.area_step.stepType : data.additional_task ? "Manual Task" : ""}
                    </dd>
                  </div>
                </dl>
                <p>&nbsp;</p>
                {data.complete && <>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                      Completed At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.complete_date && dayjs(data.complete_date).format("DD/MM/YYYY HH:mm:ss")}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Task Completed Data
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {JSON.stringify(data.complete_meta)}
                      </dd>
                    </div>
                  </dl>
                  <p>&nbsp;</p>
                </>}
                
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Fee Earner
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.assigned_name == "--" ? (matter && matter.responsible_attorney ? matter.responsible_attorney.name : "") : data.assigned_name}
                    </dd>
                  </div>
                  {!data.complete && ((data.area_step && data.area_step.selectCourt) || data.additional_task) && 
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Court
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.court ? data.court.name : "--"}
                    </dd>
                  </div>}
                  {data.area_step && data.area_step.resourceLinks && data.area_step.resourceLinks.length > 0 &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Resource Links
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">

                      {data.area_step.resourceLinks.map(link => <a href="#" className="font-medium text-primary hover:text-secondary block" onClick={() => {
                        window.open(
                          link.url,
                          "_blank"
                        ); 
                        }}>
                        <LinkIcon className="h-5 w-5 flex-shrink-0 text-gray-400 inline-block" aria-hidden="true" />
                        {link.title ? link.title : "View Resource"}
                        </a>)}
                      
                      
                      
                    </dd>
                  </div>}
                </dl>
                <p>&nbsp;</p>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {`${data.complete ? "Complete" : "Incomplete"} ${
                        data.complete
                          ? `(${
                              data.complete_date
                                ? dayjs(data.complete_date).format("DD/MM/YYYY")
                                : ""
                            })`
                          : ""
                      }`}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Time</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {`${(data.time).toFixed(2)}
                         Minutes ${data.isBillable ? "(Billable)" : "(Non-billable)"}`}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Cost</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{((data.time / 6) * (data.currentRate ? data.currentRate / 60 : 0)
                    ).toFixed(2)}
                    </dd>
                  </div>
                 
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">File</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                       {data.file && <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <span className="ml-2 w-0 flex-1 truncate">{data.file_name}</span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a href="#" className="font-medium text-primary hover:text-secondary" onClick={() => {
                            
                             API.settings
                             .getFileLink(
                                data.file
                             )
                             .then((res) => {
                               window.open(
                                 _appConfig.api_server + res,
                                 "_blank"
                               );
                             }); 
                          }}>
                            View
                          </a>
                        </div>
                      </li>}
                      {data.correspondent_files && data.correspondent_files.length > 0 && data.correspondent_files.map((file) => {
                        return (
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                          <div className="flex w-0 flex-1 items-center">
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 w-0 flex-1 truncate">{file.file_name}</span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a href="#" className="font-medium text-primary hover:text-secondary" onClick={() => {
                              
                              API.settings
                              .getFileLink(
                                  file._id
                              )
                              .then((res) => {
                                window.open(
                                  _appConfig.api_server + res,
                                  "_blank"
                                );
                              }); 
                            }}>
                              View
                            </a>
                          </div>
                        </li>)

                      })}
                    </ul>
                     
                    </dd>
                  </div> 
                </dl>
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
